<template>
  <div></div>
</template>

<script>
import Cookies from 'js-cookie'
import { expires } from '@/var/config'
import { getEnv } from '@nestark/lib'

export function getUrlWithParams(res) {
  // debugger
  const env =  Cookies.get('nauth-env') || getEnv()
  const prefix = env === 'localdev' ? `${env}.` : ''

  let redirect = Cookies.get('nauth-redirect')
  redirect = redirect ? decodeURIComponent(redirect) : ''
  console.log('redirect', redirect)

  let character = redirect.indexOf('?') !== -1 ? '&' : '?'

  let url = redirect || `http://${prefix}admin.98du.com/login`
  console.log('url', url)

  url = `${url}${character}nauth_username=${encodeURIComponent(res.username)}&nauth_userid=${encodeURIComponent(res.userId)}&nauth_dduserid=${encodeURIComponent(res.ddUserId)}&nauth_ddunionid=${encodeURIComponent(res.ddUnionid)}&nauth_token=${encodeURIComponent(res.token)}`
  return url
}

export default {
  name: 'Redirect',
  created() {
    const code = Cookies.get('nauth-code')
    const env =  Cookies.get('nauth-env')
    const prefix = (env !== 'production' && typeof env === 'string' && env.length ) ? `${env}.` : ''

    if (code) {
      this.$api.checkCode({
        baseURL: `http://${prefix}nadmin-java.uheixia.com/api`,
        params: { code }
      }).then(res => {
        this.$message.success('登录成功！')
        const domain = '.98du.com'
        const path = '/'
        const url = getUrlWithParams(res)
        const source = [
          { name: 'nauth-username', value: decodeURIComponent(res.username) },
          { name: 'nauth-userid', value: decodeURIComponent(res.userId) },
          { name: 'nauth-dduserid', value: decodeURIComponent(res.ddUserId) },
          { name: 'nauth-ddunionid', value: decodeURIComponent(res.ddUnionid) },
          { name: 'nauth-token', value: decodeURIComponent(res.token) },
        ]
        source.forEach((el) => {
          // 区分环境
          Cookies.set(`${el.name}${env === 'localdev' ? `-${env}` : ''}`, el.value, { path, domain, expires })
        })
        this.clearCookies()
        window.location.href = url
      }).catch(() => {
        this.clearCookies()
      })
    } else {
      this.$router.replace('/')
    }
  },
  methods: {
    clearCookies () {
      Cookies.remove('nauth-env', { path: '/', domain: '.98du.com' })
      Cookies.remove('nauth-code')
      Cookies.remove('nauth-redirect', { path: '/', domain: '.98du.com' })
    },
  }
}
</script>
